import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment, Link } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function CustomPasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev); // Toggle password visibility
  };

  return (
    <TextField
      name="password"
      type={showPassword ? "text" : "password"}
      autoComplete="current-password"
      variant="filled"
      placeholder="Enter your password"
      id="password"
      // label="Enter your email"
      fullWidth
      sx={{
        "& .MuiFilledInput-root": {
          marginTop: "20px",
          width: "100%",
          height: "50px",
          borderRadius: "8px", // Rounded corners
          border: "none",
          backgroundColor: "#f0f4f9 !important", // Background color of the input field
          "&:hover": {
            backgroundColor: "#f0f4f9 !important", // Same background color on hover
            border: "none",
          },
          "&.Mui-focused": {
            backgroundColor: "#f0f4f9 !important", // Same background color on focus
            border: "none",
          },
          "&::before, &::after": {
            border: "none",
            display: "none",
            // borderBottom: '2px solid #f0f4f9', // Bottom border color
          },
          "&:hover::before": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on hover
          },
          "&.Mui-focused::after": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on focus
          },
          "& .MuiFilledInput-input": {
            padding: "10px 24px", // Adjust padding for better aesthetics
            color: "#5f6368",
            backgroundColor: "#f0f4f9 !important", // Background color of the input field
          },
          "& .MuiFilledInput-input:-webkit-autofill": {
            padding: "10px 24px", // Adjust padding for better aesthetics
            color: "#5f6368",
            backgroundColor: "#f0f4f9 !important", // Background color of the input field
          },
          "&input#password:-webkit-autofill": {
            backgroundColor: "#f0f4f9 !important",
          },
        },
        "& .MuiFilledInput-input": {
          padding: "10px 24px", // Adjust padding for better aesthetics
          color: "#fff",
          backgroundColor: "#f0f4f9 !important", // Background color of the input field
          filter: "grayscale(0.6)",
          margin: "0px 4px 0px 4px",
        },
        "& .MuiFilledInput-input:-webkit-autofill": {
          padding: "10px 24px", // Adjust padding for better aesthetics
          color: "#5f6368",
          backgroundColor: "#f0f4f9 !important", // Background color of the input field
          filter: "grayscale(0.6)",
          margin: "0px 4px 0px 4px",
        },
        "&input#password:-webkit-autofill": {
          backgroundColor: "#f0f4f9 !important",
          filter: "grayscale(0.6)",
          margin: "0px 4px 0px 4px",
        },
      }}
      InputProps={{
        endAdornment: props.value && (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              edge="end"
              aria-label="toggle password visibility"
            >
              {showPassword ? (
                <Visibility sx={{ width: "18px" }} />
              ) : (
                <VisibilityOff sx={{ width: "18px" }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default CustomPasswordField;
