import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Box, CssBaseline } from "@mui/material";
import DrawerComponent from "../../components/templates/DrawerComponent";
import ChatInterface from "../../components/templates/ChatInterface/ChatInterface";
import UserMenu from "../../components/organisms/UserMenu";
import RightSideDrawer from "../../components/templates/RightSideDrawer/RightSideDrawer";

export default function Home() {
  const { logout } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRightDrawer, setIsRightDrawer] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newMessage, setNewMessage] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
    // setDrawerOpen(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    // setDrawerOpen(true);
  };
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    logout();
    handleClose();
  };
  // Callback function to open the drawer when ChatInterface gives responseText1
  const handleTickerPanel = ({ newQuestion, responseRecieved }) => {
    if (responseRecieved === true) {
      //Add this question to history panel
      console.log(newQuestion);
      // Delay the execution of the code inside the if block
      setTimeout(() => {
        setIsRightDrawer(true);
        setOpen(false);
        setDrawerOpen(true);
      }, 1000); // Delay of 500 milliseconds (0.5 seconds)
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
      <CssBaseline />
      <DrawerComponent
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
      />
      <Box sx={{ flexGrow: 1, padding: "10px 24px" }}>
        <UserMenu
          anchorEl={anchorEl}
          handleMenu={handleMenu}
          handleClose={handleClose}
          handleLogout={handleLogout}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
        />
        <ChatInterface
          onResponse={handleTickerPanel}
          open={open && drawerOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
        />
      </Box>
      {isRightDrawer && (
        <RightSideDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          handleDrawerClose={handleDrawerClose}
        />
      )}
    </Box>
  );
}
