import React from "react";
import { LinearProgress, styled, Grid, Paper, Typography } from "@mui/material";

// Create a styled version of LinearProgress to customize animation speed
const SlowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  padding: "15px",
  textAlign: "left",
  width: "100%", // Full width to fill the grid item
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  backgroundColor: "#fffcfa",
  border: "1px solid #fff9f2",
  borderRadius: "10px",
  boxShadow: "none",
  "& .MuiLinearProgress-bar": {
    transition: "transform 5s ease-out", // Adjust duration to make it slower
    backgroundColor: "#e6d5bf",
    opacity: 0.1,
  },
}));

const CustomSuggestionProgress = (props) => {
  return (
    <Grid container spacing={2} sx={{ marginTop: "20px" }}>
      {[3, 2, 3, 4].map((item, index) => (
        <Grid
          item
          xs={12} // Full width on small screens
          sm={6} // 50% width on small screens
          md={6} // 50% width on medium screens
          lg={6} // 50% width on large screens
          key={index}
        >
          <Paper
            elevation={3}
            sx={{
              padding: "15px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              backgroundColor: "#fffcfa",
              border: "1px solid #fff9f2",
              borderRadius: "10px",
              boxShadow: "none",
              cursor: "pointer",
              alignSelf: index % 2 === 0 ? "flex-start" : "flex-end", // Alternate alignment for left and right
            }}
          >
            <SlowLinearProgress
              style={{ width: 100 * item }} // Multiply item value to set progress percentage
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomSuggestionProgress;
