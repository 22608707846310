import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  IconButton,
} from "@mui/material";

// Styled components for the dialog sections
export const DialogSectionI = styled(Box)(({ theme, moved }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  transition: theme.transitions.create(["transform", "opacity"], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  transform: moved ? "translateX(-100%)" : "translateX(0)",
  opacity: moved ? 0 : 1,
}));

export const DialogSectionII = styled(Box)(({ theme, moved }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  transition: theme.transitions.create(["transform", "opacity"], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  transform: moved ? "translateX(-100%)" : "translateX(0)",
  opacity: moved ? 0 : 1,
}));

// Styled Dialog component
export const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    // width: "50vw", // Base width for laptops
    // height: "66vh", // Base height for laptops
    maxWidth: "600px", // Maximum width constraint
    maxHeight: "700px", // Maximum height in pixels
    overflow: "hidden",
    borderRadius: "12px",
    alignItems: "center",
    padding: "25px",
    // Responsive adjustments for smaller screens
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: "60vh",
      maxWidth: "500px",
      maxHeight: "600px",
    },
    // Fullscreen adjustments for extra-small screens (mobile)
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      borderRadius: "0px", // No border radius for full-screen mobile view
    },
  },
}));

export const StyledDialogRegister = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    // width: "50vw", // Base width for laptops
    // height: "66vh", // Base height for laptops
    maxWidth: "600px", // Maximum width constraint
    maxHeight: "800px", // Maximum height in pixels
    overflow: "hidden",
    borderRadius: "12px",
    // display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    // Responsive adjustments for smaller screens
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: "60vh",
      maxWidth: "500px",
      maxHeight: "600px",
    },
    // Fullscreen adjustments for extra-small screens (mobile)
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      borderRadius: "0px", // No border radius for full-screen mobile view
    },
  },
}));

export const StyledDialogVerify = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    // width: "50vw", // Base width for laptops
    // height: "60vh", // Base height for laptops
    maxWidth: "600px", // Maximum width constraint
    maxHeight: "800px", // Maximum height in pixels
    overflow: "hidden",
    borderRadius: "12px",
    alignItems: "center",
    padding: "25px",
    // Responsive adjustments for smaller screens
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: "60vh",
      maxWidth: "500px",
      maxHeight: "600px",
    },
    // Fullscreen adjustments for extra-small screens (mobile)
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      borderRadius: "0px", // No border radius for full-screen mobile view
    },
  },
}));

// Styled DialogTitle component
export const StyledDialogTitleI = styled(MuiDialogTitle)(({ theme }) => ({
  fontSize: "36px",
  // marginBottom: "20px",
  // minHeight: "10vh",
}));

export const StyledDialogTitleRegisterI = styled(MuiDialogTitle)(
  ({ theme }) => ({
    fontSize: "36px",
    marginBottom: "20px",
  })
);

// Styled DialogTitle component
export const StyledDialogTitleII = styled(MuiDialogTitle)(({ theme }) => ({
  fontSize: "15px",
  marginBottom: "50px",
  textAlign: "center",
  "& strong": {
    fontSize: "18px",
  },
}));

// Styled DialogTitle component
export const StyledDialogTitleIII = styled(MuiDialogTitle)(({ theme }) => ({
  fontSize: "15px",
  marginBottom: "50px",
  textAlign: "center",
  "& strong": {
    fontSize: "25px",
  },
}));

// Styled DialogContent component
export const StyledDialogContent = styled(MuiDialogTitle)(({ theme }) => ({
  flex: "none",
  width: "80%",
  textAlign: "center",
}));

export const StyledDialogContentVerify = styled(MuiDialogTitle)(
  ({ theme }) => ({
    flex: "none",
    width: "80%",
    textAlign: "left",
  })
);
// Styled DialogActions component
export const StyledDialogActions = styled(MuiDialogActions)(({ theme }) => ({
  // marginBottom: "50px",
  width: "100%",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
}));

// Styled component for the underline effect
export const StyledStrong = styled("strong")(({ theme }) => ({
  cursor: "pointer",
  color: "#1976d2",
  marginLeft: "4px",
  textDecoration: "none",
  transition: "text-decoration 0.3s ease",
  "&:hover": {
    textDecoration: "underline",
  },
}));

// Styled component for the underline effect
export const StyledStrongAction = styled("strong")(({ theme }) => ({
  cursor: "pointer",
  color: "#1976d2",
  marginLeft: "4px",
  textDecoration: "none",
  transition: "text-decoration 0.3s ease",
  "&:hover": {
    textDecoration: "underline",
  },
}));

// Styled component for Typography with underline effect on hover
export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "500",
  padding: "10px",
  display: "flex",
  alignItems: "center",
}));

// Styled component for Typography with underline effect on hover
export const StyledTypographyII = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  fontSize: "12px",
  cursor: "pointer",
  color: "#1976d2",
  fontWeight: "500",
  padding: "10px",
  textDecoration: "none", // Remove default underline
  "&:hover": {
    textDecoration: "underline", // Add underline on hover
  },
}));

// Styled component for Typography with underline effect on hover
export const StyledTypographyLabel = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  fontSize: "12px",
  cursor: "pointer",
  color: "#1976d2",
  fontWeight: "500",
  padding: "10px",
  textDecoration: "none", // Remove default underline
  "&:hover": {
    textDecoration: "underline", // Add underline on hover
  },
}));
// Styled component for IconButton
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "20px",
  top: "10px",
  color: "lightgray",
}));
