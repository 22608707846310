// StyledComponents.jsx
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";

// Container for the TextField and Paragraph
export const StyledContainer = styled(Box)(({ theme }) => ({
  marginTop: "auto",
}));

// Styled Paragraph component
export const StyledParagraph = styled(Typography)(({ theme }) => ({
  color: "#7D7D7D",
  fontFamily: '"Google Sans", "Helvetica Neue", sans-serif',
  fontSize: "12px",
  margin: "10px 20px",
  fontWeight: 500,
  display: "flex",
  justifyContent: "center",
  "& span": {
    textDecoration: "underline",
  },
}));

// Styled Paragraph component
export const StyledPaper = styled(Paper)(({ theme, isMobile, open }) => ({
  padding: isMobile ? "0px" : open ? "0px" : "0px 6vw",
  display: "flex",
  flexDirection: "column",
  border: "none",
  boxShadow: "none",
  height: "93vh",
}));

// Container for messages
export const StyledMessageContainer = styled(Box)(({ theme }) => ({
  height: "78vh",
  display: "flex",
  flexDirection: "column",
}));

// Styled List component
export const StyledList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  scrollbarWidth: "none",
}));

// Styled ListItem component
export const StyledListItem = styled(ListItem)(({ theme }) => ({
  alignItems: "flex-start",
}));

// Styled ListItemText component
export const StyledListItemText = styled(ListItemText)(
  ({ theme, isQuestion }) => ({
    backgroundColor: isQuestion ? "#fff9f2" : "transparent",
    padding: isQuestion ? "10px" : "0px",
    ".answer-message-content": {
      lineHeight: 1.75,
      p: {
        margin: 0,
      },
      span: {
        backgroundColor: "#f0fff0",
      },
      question: {
        display: "block",
        alignItems: "center",
        backgroundColor: "#fff9f2",
        marginTop: "10px",
        width: "fit-content",
        padding: "5px",
        border: "2px solid #fff9f2",
        cursor: "pointer",
      },
      service: {
        display: "block",
        alignItems: "center",
        backgroundColor: "rgb(237, 247, 237)",
        marginTop: "10px",
        width: "fit-content",
        padding: "5px",
        border: "2px solid rgb(237, 247, 237)",
        cursor: "pointer",
      },
      advisor: {
        display: "block",
        alignItems: "center",
        backgroundColor: "rgb(237, 247, 237)",
        marginTop: "10px",
        width: "fit-content",
        padding: "5px",
        border: "2px solid rgb(237, 247, 237)",
        cursor: "pointer",
      },
      table: {
        border: "0.1px solid rgba(0, 0, 0, .1) !important",
        borderCollapse: "separate",
        borderSpacing: "0px 0px",
        marginBottom: ".25rem",
        marginTop: ".25rem",
        width: "100%",
        fontSize: ".875em",
        lineHeight: "1.7142857",
        tableLayout: "auto",
        borderColor: "inherit",
        textIndent: "0",
      },
      th: {
        borderBottom: "none",
        borderLeft: "0.3px solid rgba(0, 0, 0, .15)",
        backgroundColor: "#f0f4f9",
        padding: "2px",
      },
      td: {
        borderBottom: "0.3px solid rgba(0, 0, 0, 0.15)",
        borderLeft: "0.3px solid rgba(0, 0, 0, .15)",
        padding: "2px",
      },
      code: {
        display: "none",
      },
      pre: {
        display: "none",
      },
    },
    ".question-message-content": {
      lineHeight: 1.75,
      fontWeight: 500,
    },
  })
);

// Styled Paragraph for no messages
export const StyledNoMessagesText = styled(Typography)(({ theme }) => ({
  background:
    "linear-gradient(to bottom,#7ecd40 0,#61bf35 4%,#5eaf39 7%,#59a42e 11%,#50a331 14%,#4da324 18%,#54a131 21%,#4e9e2d 29%,#489128 39%,#468c29 43%,#408322 50%,#3e7c24 54%,#37761e 57%,#346f1c 61%,#306419 71%,#32651a 79%,#2f6818 82%,#366d1d 86%,#376d1f 89%,#387621 93%,#377d1a 96%,#437f25 100%)",
  backgroundClip: "text",
  color: "transparent",
  fontFamily: '"Google Sans","Helvetica Neue",sans-serif',
  fontSize: "55px",
  margin: 0,
  fontWeight: 500,
}));

export const StyledSubheading = styled(Typography)(({ theme }) => ({
  color: "lightgrey",
  fontFamily: '"Google Sans","Helvetica Neue",sans-serif',
  fontSize: "50px",
  marginTop: "-10px",
  fontWeight: 500,
}));
