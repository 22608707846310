import React from "react";
import {
  DialogActions,
  CircularProgress,
  IconButton,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CustomLoginField from "../../molecules/CustomTextField/CustomLoginField";
import CustomPasswordField from "../../molecules/CustomTextField/CustomPasswordField";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import zackLogo from "../../../assests/zackLogo.png";
import {
  DialogSectionI,
  DialogSectionII,
  StyledStrong,
  StyledTypography,
  StyledTypographyII,
  StyledTypographyLabel,
  StyledDialog,
  StyledDialogRegister,
  StyledDialogTitleRegisterI,
  StyledDialogTitleI,
  StyledDialogTitleII,
  StyledDialogTitleIII,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
} from "./LoginDialogStyled";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";

const RegisterDialog = ({
  open,
  onClose,
  onRegister,
  username,
  setUsername,
  newPassword,
  setNewPassword,
  retypePassword,
  setRetypePassword,
  isPasswordMatch,
  setIsPasswordMatch,
  loading,
  setLoading,
  APIError,
  setAPIError,
  isEmailValid,
  setIsEmailValid,
  setOpenLoginDialog,
  transit,
  setTransit,
  setOpenVerificationDialog,
  sendVerificationEmail,
}) => {
  const handleEmailChange = (value) => {
    setLoading(false);
    setUsername(value);
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
    setIsEmailValid(true);
  };
  const handlePasswordChange = (value) => {
    setLoading(false);
    setNewPassword(value);
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
    setIsEmailValid(true);
  };
  const handleReTypePasswordChange = (value) => {
    setLoading(false);
    setRetypePassword(value);
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
    setIsEmailValid(true);
  };

  const handleLoginClick = async () => {
    setTransit(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    setOpenLoginDialog(true);
    setTransit(false);
    setNewPassword("");
    setRetypePassword("");
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
    setIsEmailValid(true);
  };
  return (
    <StyledDialogRegister open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <DialogSectionI moved={transit}>
        <StyledDialogTitleRegisterI>
          <Typography variant="h6" align="center">
            Create your account
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            We recommend using your personal email and strong password.
          </Typography>
        </StyledDialogTitleRegisterI>
        <StyledDialogContent>
          <CustomLoginField
            value={username}
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter email"
          />
          <CustomPasswordField
            value={newPassword}
            placeholder="Enter Password"
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <CustomPasswordField
            value={retypePassword}
            placeholder="Re-enter Password"
            onChange={(e) => handleReTypePasswordChange(e.target.value)}
          />
          {APIError.length > 0 &&
            APIError === "Please verify your email address." && (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
                <Typography
                  variant="body2"
                  component="span"
                  // onClick={handleLoginClick}
                  style={{
                    color: "#007f06",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Resend
                </Typography>
              </Alert>
            )}
          {APIError.length > 0 &&
            APIError !== "Please verify your email address." && (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
              </Alert>
            )}
          {isEmailValid === false && (
            <Alert severity="warning" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Please provide a genuine email address.
            </Alert>
          )}
          {isPasswordMatch === false && (
            <Alert severity="error" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Passwords do not match.
            </Alert>
          )}
        </StyledDialogContent>
        <StyledDialogActions>
          {loading ? (
            <CustomButton color="log" variant="contained">
              <CustomThreeDotLoader />
            </CustomButton>
          ) : (
            <CustomButton color="log" variant="contained" onClick={onRegister}>
              Continue
            </CustomButton>
          )}
          <Typography variant="body2" style={{ marginTop: "20px" }}>
            Already a user?{" "}
            <Typography
              variant="body2"
              component="span"
              onClick={handleLoginClick} // Call the register click handler here
              style={{
                color: "#007f06", // Change color as needed
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Login
            </Typography>
          </Typography>
        </StyledDialogActions>
      </DialogSectionI>
    </StyledDialogRegister>
  );
};

export default RegisterDialog;
