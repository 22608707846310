import React from "react";
import TextField from "@mui/material/TextField";

function CustomTextField(props) {
  return (
    <TextField
      variant="filled"
      placeholder="Enter your query here"
      fullWidth
      sx={{
        "& .MuiFilledInput-root": {
          borderRadius: "100px", // Rounded corners
          minHeight: "64px",
          border: "none",
          backgroundColor: "#f0f4f9", // Background color of the input field
          "&:hover": {
            backgroundColor: "#f0f4f9", // Same background color on hover
            border: "none",
          },
          "&.Mui-focused": {
            backgroundColor: "#f0f4f9", // Same background color on focus
            border: "none",
          },
          "&::before, &::after": {
            border: "none",
            display: "none",
            // borderBottom: '2px solid #f0f4f9', // Bottom border color
          },
          "&:hover::before": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on hover
          },
          "&.Mui-focused::after": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on focus
          },
        },
        "& .MuiFilledInput-input": {
          padding: "10px 24px", // Adjust padding for better aesthetics
          color: "#5f6368",
        },
      }}
      {...props}
    />
  );
}

export default CustomTextField;
