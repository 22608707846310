import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomListItem from "../atoms/List/CustomListItem";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { fetchData } from "../../services/apiConfig";
import CustomHistoryProgress from "../atoms/Progress/CustomHistoryProgress";

const drawerWidth = "20vw";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: "cubic-bezier(0.4, 0, 0.2, 1)",
    duration: 600,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: "cubic-bezier(0.4, 0, 0.2, 1)",
    duration: 600,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function DrawerComponent({
  open,
  onOpen,
  onClose,
  newMessage,
  setNewMessage,
}) {
  const [historyquestions, setHistoryquestion] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is small
  useEffect(() => {
    // Focus the input on component mount
    try {
      const email = localStorage.getItem("email");
      if (email) {
        fetchData("getHistory", "POST", {
          email: email,
          k: 5,
        }).then((response) => {
          setHistoryquestion(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box>
      {/* Drawer */}
      {isMobile ? (
        <MuiDrawer
          variant="temporary"
          open={!open}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: 300,
              backgroundColor: "#f0f4f9",
              border: "none",
            },
          }}
          ModalProps={{ keepMounted: true }} // Better performance on mobile
        >
          <DrawerHeader>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={onOpen}
              edge="start"
              sx={{ marginLeft: 1.5 }}
            >
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
          <List>
            {["New chat"].map((text) => (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 40,
                    padding: "0px 20px",
                    width: "fit-content",
                    marginLeft: "10px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      marginRight: "10px",
                      backgroundColor: "#dde3ea",
                      borderRadius: "20px",
                    }}
                  >
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "#f0f4f9",
              border: "none",
              minWidth: "68px !important",
            },
          }}
        >
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? onClose : onOpen}
              edge="start"
              sx={{ marginLeft: 1.5 }}
            >
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
          <Typography
            style={{
              marginLeft: "30px",
              fontSize: "14px",
              fontWeight: "500",
              opacity: open ? 1 : 0,
              marginTop: "10vh",
            }}
          >
            Recent
          </Typography>
          <List>
            {historyquestions.length > 0
              ? historyquestions.map((prompts) => (
                  <ListItem
                    key={prompts.promt_id}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      setNewMessage(prompts.prompt);
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 40,
                        padding: "0px 20px",
                        width: "95%",
                        marginLeft: "10px",
                        border: "1px solid transparent",
                        borderRadius: "25px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: "center",
                          marginRight: "10px",
                          borderRadius: "20px",
                        }}
                      >
                        <ChatBubbleOutlineIcon
                          style={{
                            opacity: open ? 1 : 0,
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </ListItemIcon>
                      <CustomListItem
                        primary={prompts.prompt}
                        open={open}
                        sx={{ opacity: open ? 1 : 0, fontSize: "2px" }}
                        // setNewMessage={setNewMessage}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              : [1, 2, 3, 4, 5].map((index) => (
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 40,
                        padding: "0px 20px",
                        width: "95%",
                        marginLeft: "10px",
                        border: "1px solid transparent",
                        borderRadius: "25px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: "center",
                          marginRight: "10px",
                          borderRadius: "20px",
                        }}
                      >
                        <ChatBubbleOutlineIcon
                          style={{
                            opacity: open ? 1 : 0,
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </ListItemIcon>
                      <CustomHistoryProgress />
                    </ListItemButton>
                  </ListItem>
                ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
}
