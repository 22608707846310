const API_BASE_URL = "https://api.zacksinsight.com/user-mgmt"; // Replace with your actual API base URL

const apiConfig = {
  endpoints: {
    // Define API endpoints here
    register: `${API_BASE_URL}/api/v1/auth/fastapi-users/auth/register`,
    login: `${API_BASE_URL}/api/v1/auth/fastapi-users/auth/login`,
    requestVerifyToken: `${API_BASE_URL}/api/v1/auth/fastapi-users/auth/request-verify-token`,
    verifyToken: `${API_BASE_URL}/api/v1/auth/fastapi-users/auth/verify`,
    requestPasswordVerification: `${API_BASE_URL}/api/v1/auth/fastapi-users/auth/forgot-password`,
    resetPassword: `${API_BASE_URL}/api/v1/auth/fastapi-users/auth/reset-password`,

    getResponse: `https://api.zacksinsight.com/backend/ai_services/get_zack_answer`, // Get chat messages
    getHistory: `https://api.zacksinsight.com/backend/chatdb_services/get_user_chat_questions`,
    getSuggest: `https://api.zacksinsight.com/backend/ai_services/get_user_seed_questions`,
    submitFeedback: `https://api.zacksinsight.com/backend/chatdb_services/update_chat_turn_with_feedback`,
  },
};

export const fetchAuthData = async (endpoint, method, body, params = {}) => {
  const url = new URL(apiConfig.endpoints[endpoint]);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (body) {
    options.body = JSON.stringify(body); // Convert body to JSON string
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      let errorData;
      const contentType = response.headers.get("Content-Type");
      const status = response.status;
      if (contentType && contentType.includes("application/json")) {
        errorData = await response.json();
      } else {
        errorData = await response.text();
      }
      console.error("Error data:", errorData);
      // throw new Error(`HTTP error! status: ${response.status}`);
      return errorData;
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    // throw error; // Rethrow the error for further handling
  }
};
export const fetchAuthDataLogin = async (endpoint, method, body) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  if (body) {
    const formBody = Object.keys(body)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(body[key])
      )
      .join("&");
    options.body = formBody;
  }

  try {
    const response = await fetch(apiConfig.endpoints[endpoint], options);
    if (!response.ok) {
      let errorData;
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        errorData = await response.json();
      } else {
        errorData = await response.text();
      }
      console.error("Error data:", errorData);
      // throw new Error(`HTTP error! status: ${response.status}`);
      return errorData;
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    // throw error; // Rethrow the error for further handling
  }
};

// Fetch data function
export const fetchData = async (endpoint, method, body) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      // Add other headers if necessary (e.g., Authorization)
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(apiConfig.endpoints[endpoint], options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    // throw error; // Rethrow the error for further handling
  }
};

export const fetchStream = async (
  endpoint,
  method,
  body,
  setMessages,
  setStatus,
  abortController
) => {
  const options = {
    method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      // Add other headers if necessary (e.g., Authorization)
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  // If an AbortController is passed, add its signal to the fetch options
  if (abortController) {
    options.signal = abortController.signal;
  }
  try {
    // Send the POST request
    const response = await fetch(apiConfig.endpoints[endpoint], options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = "";

    let done = false;
    let fullresponse = "";
    while (!done) {
      if (abortController.signal.aborted) {
        console.log("Request aborted during streaming");
        break; // Exit the loop if the request is aborted
      }
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) {
        // Decode the current chunk
        buffer += decoder.decode(value, { stream: true });

        // Split the buffer by known JSON boundaries (you could use newlines or other markers if provided)
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("\n")) !== -1) {
          const jsonChunk = buffer.slice(0, boundaryIndex + 1); // Take the first complete JSON object
          buffer = buffer.slice(boundaryIndex + 1); // Keep the rest for the next chunk
          try {
            const parsedData = JSON.parse(jsonChunk);
            if (
              parsedData.message_type === "model_response_chunk" &&
              parsedData.content !== "```" &&
              parsedData.content !== "html"
            ) {
              let newContent = parsedData.content;
              // Check if the new chunk is empty or contains just whitespace
              if (newContent.match(/^\s{2,}/) || newContent.match(/\s{2,}$/)) {
                // Trim only if there is more than 1 whitespace at the start or end
                newContent = newContent.trim();
              }
              if (!newContent) {
                // return; // Skip processing this chunk if it's empty or only whitespace
              } else {
                fullresponse += newContent;
                await new Promise((resolve) => setTimeout(resolve, 20));
                // eslint-disable-next-line no-loop-func
                setMessages((prevMessages) => [
                  ...prevMessages.slice(0, -1),
                  {
                    text: fullresponse,
                    isLoading: false,
                    isStreaming: true,
                  },
                ]);
              }
            } else if (parsedData.message_type === "status") {
              setStatus(parsedData.content);
              await new Promise((resolve) => setTimeout(resolve, 200));
            } else if (parsedData.message_type === "session_data") {
              console.log("here is the prompt id");
              if (parsedData.content && parsedData.content.prompt_id) {
                // eslint-disable-next-line no-loop-func
                setMessages((prevMessages) => [
                  ...prevMessages.slice(0, -1),
                  {
                    text: fullresponse,
                    isLoading: false,
                    isLoadingError: false,
                    isStreaming: false,
                    isFeedback: true,
                    promptId: parsedData.content.prompt_id,
                  },
                ]);
              }
              console.log(parsedData.content);
            }
          } catch (err) {
            console.error("Error parsing JSON:", err, jsonChunk);
          }
        }
      }
    }

    // Once done, you might have the final valid JSON in the buffer
    if (buffer.length > 0) {
      try {
        console.log("parsing final chunk");
        const parsedData = JSON.parse(buffer); // Parse the final chunk
        if (
          parsedData.message_type === "model_response_chunk" &&
          parsedData.content !== "```" &&
          parsedData.content !== "html"
        ) {
          fullresponse += parsedData.content;
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            { text: fullresponse, isLoading: false, isStreaming: false },
          ]);
        } else if (parsedData.message_type === "status") {
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              text: "Sorry server is busy at the moment. Please try again later.",
              isLoading: false,
              isLoadingError: true,
              isStreaming: false,
            },
          ]);
        } else if (parsedData.message_type === "error") {
          console.log(parsedData);
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              text: "An error has occurred. Please try again later.",
              isLoading: false,
              isLoadingError: true,
              isStreaming: false,
            },
          ]);
        } else if (parsedData.message_type === "session_data") {
          console.log("here is the prompt id");
          if (parsedData.content && parsedData.content.prompt_id) {
            setMessages((prevMessages) => [
              ...prevMessages.slice(0, -1),
              {
                text: fullresponse,
                isLoading: false,
                isLoadingError: false,
                isStreaming: false,
                isFeedback: true,
                promptId: parsedData.content.prompt_id,
              },
            ]);
          }
          console.log(parsedData.content);
        }
      } catch (err) {
        console.error("Error parsing final JSON chunk:", err, buffer);
      }
    }

    reader.releaseLock();
  } catch (error) {
    console.error("Fetch error:", error);
    if (abortController && abortController.signal.aborted) {
      console.log("Request was aborted");
    } else {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          text: "Service is down at the moment. Please try again later.",
          isLoading: false,
          isLoadingError: true,
        },
      ]);
    }
  } finally {
    setStatus("Loading...");
    console.log("finally done");
  }
};

export const fetchStreamMarkDown = async (
  endpoint,
  method,
  body,
  setMessages,
  setStatus
) => {
  const options = {
    method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      // Add other headers if necessary (e.g., Authorization)
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  console.log(options);

  try {
    // Send the POST request
    const response = await fetch(apiConfig.endpoints[endpoint], options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = "";

    let done = false;
    let fullresponse = "";
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) {
        // Decode the current chunk
        buffer += decoder.decode(value, { stream: true });

        // Split the buffer by known JSON boundaries (you could use newlines or other markers if provided)
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}{")) !== -1) {
          const jsonChunk = buffer.slice(0, boundaryIndex + 1); // Take the first complete JSON object
          buffer = buffer.slice(boundaryIndex + 1); // Keep the rest for the next chunk
          try {
            const parsedData = JSON.parse(jsonChunk);
            if (
              parsedData.message_type === "model_response_chunk" &&
              parsedData.content !== "```" &&
              parsedData.content !== "html"
            ) {
              const newContent = parsedData.content;
              // let formattedContent = newContent.replace(/ {2,}/g, (match) =>
              //   match.replace(/ /g, "&nbsp;")
              // );
              let formattedContent = newContent.replace(/\n/g, "  \n");
              fullresponse += formattedContent;

              await new Promise((resolve) => setTimeout(resolve, 50));
              // eslint-disable-next-line no-loop-func
              setMessages((prevMessages) => [
                ...prevMessages.slice(0, -1),
                {
                  text: fullresponse,
                },
              ]);
              // }
            } else if (parsedData.message_type === "status") {
              console.log("here is the status");
              await new Promise((resolve) => setTimeout(resolve, 50));
              setStatus(parsedData.content);
            }
          } catch (err) {
            console.error("Error parsing JSON:", err, jsonChunk);
          }
        }
      }
    }

    // Once done, you might have the final valid JSON in the buffer
    if (buffer.length > 0) {
      try {
        console.log("parsing final chunk");
        const parsedData = JSON.parse(buffer); // Parse the final chunk
        if (
          parsedData.message_type === "model_response_chunk" &&
          parsedData.content !== "```" &&
          parsedData.content !== "html"
        ) {
          fullresponse += parsedData.content;
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            { text: fullresponse },
          ]);
        } else if (parsedData.message_type === "status") {
          console.log("here is the final status");
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              text: "Sorry server is busy at the moment. Please try again later.",
              isLoading: false,
              isLoadingError: true,
            },
          ]);
        } else if (parsedData.message_type === "error") {
          console.log("here is the final error");
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              text: "An error has occurred. Please try again later.",
              isLoading: false,
              isLoadingError: true,
            },
          ]);
        }
      } catch (err) {
        console.error("Error parsing final JSON chunk:", err, buffer);
      }
    }

    reader.releaseLock();
  } catch (error) {
    console.error("Fetch error:", error);
    setMessages((prevMessages) => [
      ...prevMessages.slice(0, -1),
      {
        text: "An error has occurred. Please try again later.",
        isLoading: false,
        isLoadingError: true,
      },
    ]);
  } finally {
    setStatus("Loading...");
    console.log("finally done");
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

export default apiConfig;
