import React, { useState, useContext, useEffect } from "react";
import {
  DialogActions,
  CircularProgress,
  IconButton,
  Alert,
  AlertTitle,
  Box,
  Typography,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CustomLoginField from "../../molecules/CustomTextField/CustomLoginField";
import CustomPasswordField from "../../molecules/CustomTextField/CustomPasswordField";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import zackLogo from "../../../assests/zackLogo.png";
import {
  DialogSectionI,
  DialogSectionII,
  StyledStrong,
  StyledTypography,
  StyledTypographyII,
  StyledDialog,
  StyledDialogTitleI,
  StyledDialogTitleII,
  StyledDialogTitleIII,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
} from "./LoginDialogStyled";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";

const LoginDialog = ({
  open,
  onClose,
  onLogin,
  username,
  setUsername,
  password,
  setPassword,
  loading,
  setLoading,
  APIError,
  setAPIError,
  isEmailValid,
  setIsEmailValid,
  setOpenRegisterDialog,
  transit,
  setTransit,
  sendVerificationEmail,
  sendPasswordVerificationEmail,
  verificationError,
  setVerificationError,
  passwordResetDone,
}) => {
  const handleEmailChange = (value) => {
    setLoading(false);
    setUsername(value);
    setAPIError(false);
    setIsEmailValid(true);
    setVerificationError("");
  };
  const handlePasswordChange = (value) => {
    setLoading(false);
    setPassword(value);
    setAPIError(false);
    setIsEmailValid(true);
    setVerificationError("");
  };

  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") {
      onLogin(); // Call the same function as the button click
    }
  };

  const handleRegisterClick = async () => {
    setTransit(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    setOpenRegisterDialog(true);
    setTransit(false);
    setPassword("");
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <DialogSectionI moved={transit}>
        <StyledDialogTitleI>
          <img
            src={zackLogo}
            alt="Login"
            style={{ height: "38px", width: "50px" }}
          />{" "}
          Welcome to Zack
        </StyledDialogTitleI>
        <StyledDialogContent>
          {verificationError.length > 0 && (
            <Alert severity="info" sx={{ marginBottom: "10px" }}>
              {verificationError}
            </Alert>
          )}
          {passwordResetDone && (
            <Alert severity="success" sx={{ marginBottom: "10px" }}>
              Your password has been reset successfully!
            </Alert>
          )}
          <CustomLoginField
            value={username}
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter email"
          />
          <CustomPasswordField
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            placeholder="Enter password"
            onKeyDown={handlePasswordKeyDown}
          />
          <div style={{ marginTop: "8px", textAlign: "right" }}>
            <Typography
              variant="body2"
              component="span"
              onClick={sendPasswordVerificationEmail} // Call the reset password click handler here
              style={{
                color: "#444746", // Change color as needed
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Forgot Password?
            </Typography>
          </div>

          {isEmailValid === false && (
            <Alert severity="warning" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> Please provide a genuine email address.
            </Alert>
          )}
          {APIError.length > 0 &&
            APIError === "Please verify your email address." && (
              <Alert severity="warning" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
                <Typography
                  variant="body2"
                  component="span"
                  onClick={sendVerificationEmail}
                  style={{
                    color: "rgb(102, 60, 0)",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "4px",
                    fontWeight: "bold",
                  }}
                >
                  Resend
                </Typography>
              </Alert>
            )}
          {APIError.length > 0 &&
            APIError !== "Please verify your email address." && (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
              </Alert>
            )}
        </StyledDialogContent>
        <StyledDialogActions>
          {loading ? (
            <CustomButton color="log" variant="contained">
              <CustomThreeDotLoader />
            </CustomButton>
          ) : (
            <CustomButton color="log" variant="contained" onClick={onLogin}>
              Continue
            </CustomButton>
          )}
          <Typography variant="body2" style={{ marginTop: "20px" }}>
            New user?{" "}
            <Typography
              variant="body2"
              component="span"
              onClick={handleRegisterClick} // Call the register click handler here
              style={{
                color: "#007f06", // Change color as needed
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Create your account
            </Typography>
          </Typography>
        </StyledDialogActions>
      </DialogSectionI>
    </StyledDialog>
  );
};

export default LoginDialog;
