import React from "react";
import {
  DialogActions,
  CircularProgress,
  IconButton,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CustomLoginField from "../../molecules/CustomTextField/CustomLoginField";
import CustomPasswordField from "../../molecules/CustomTextField/CustomPasswordField";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import zackLogo from "../../../assests/zackLogo.png";
import {
  DialogSectionI,
  DialogSectionII,
  StyledStrong,
  StyledTypography,
  StyledTypographyII,
  StyledTypographyLabel,
  StyledDialog,
  StyledDialogRegister,
  StyledDialogTitleRegisterI,
  StyledDialogTitleI,
  StyledDialogTitleII,
  StyledDialogTitleIII,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
  StyledDialogContentVerify,
  StyledDialogVerify,
} from "./LoginDialogStyled";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";

const VerificationDialog = ({
  open,
  onClose,
  setOpenRegisterDialog,
  setOpenLoginDialog,
  username,
  transit,
  setTransit,
  loading,
  sendVerificationEmail,
  APIError,
  isResettingPassword,
  sendPasswordVerificationEmail,
  // verificationFailed,
}) => {
  return (
    <StyledDialogVerify open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      {/* {verificationFailed ? (
        <DialogSectionII moved={transit}>
          <StyledDialogTitleI>Verify your email</StyledDialogTitleI>
          <StyledDialogContentVerify>
            <Box mb={2} />
            <Alert severity="error" color="error">
              <AlertTitle>Verification Failed</AlertTitle>
              An error occurred while verifying your email. Please try again.
            </Alert>

            {APIError.length > 0 && (
              <Alert severity="success" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
              </Alert>
            )}
          </StyledDialogContentVerify>
          <Box mb={2} />
          <StyledDialogActions>
            {loading ? (
              <CustomButton color="log" variant="contained">
                <CustomThreeDotLoader />
              </CustomButton>
            ) : (
              <CustomButton
                color="log"
                variant="contained"
                onClick={() => window.open("https://mail.google.com", "_blank")}
              >
                Go to email
              </CustomButton>
            )}
          </StyledDialogActions>
        </DialogSectionII>
      ) : ( */}
      <DialogSectionII moved={transit}>
        <StyledDialogTitleI>
          {isResettingPassword ? "Reset your password" : "Verify your email"}
        </StyledDialogTitleI>
        <StyledDialogContentVerify>
          <Box mb={2} />
          {loading ? (
            <Alert severity="info" color="info">
              <AlertTitle>
                {isResettingPassword
                  ? "Reset your password"
                  : "Verification Pending"}
                <CustomThreeDotLoader />
              </AlertTitle>
              An email is being sent to <strong>{username}</strong>.
            </Alert>
          ) : (
            <Alert severity="info" color="info">
              <AlertTitle>
                {" "}
                {isResettingPassword
                  ? "Reset your password"
                  : "Verification Pending"}
              </AlertTitle>
              {isResettingPassword ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `An email has been sent to <strong>${username}</strong>. Please click on the re-set password link in the email.`,
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `An email has been sent to <strong>${username}</strong>. Please click on the verification link in the email.`,
                  }}
                />
              )}
            </Alert>
          )}

          {APIError.length > 0 && (
            <Alert severity="success" sx={{ marginTop: "10px" }}>
              <strong>Error:</strong> {APIError}
            </Alert>
          )}
        </StyledDialogContentVerify>
        <Box mb={2} />
        <StyledDialogActions>
          {loading ? (
            <CustomButton color="log" variant="contained">
              <CustomThreeDotLoader />
            </CustomButton>
          ) : (
            <CustomButton
              color="log"
              variant="contained"
              onClick={() => window.open("https://mail.google.com", "_blank")}
            >
              Go to email
            </CustomButton>
          )}

          <StyledTypography>
            Did not receive email?{" "}
            <StyledStrong
              onClick={
                isResettingPassword
                  ? sendPasswordVerificationEmail
                  : sendVerificationEmail
              }
            >
              Resend email
            </StyledStrong>
          </StyledTypography>
        </StyledDialogActions>
      </DialogSectionII>
      {/* )} */}
    </StyledDialogVerify>
  );
};

export default VerificationDialog;
