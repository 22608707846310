import {
  Typography,
  Box,
  IconButton,
  Divider,
  Chip,
  Grid,
  Button,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, DrawerHeader, Stacker } from "./RightSideDrawerStyles"; // Import styled components

const stockData = {
  MSFT: {
    name: "Microsoft",
    symbol: "MSFT",
    price: "$415.13",
    change: "+0.46 (0.11%)",
    afterMarket: "$415.07",
    afterMarketChange: "-0.06 (-0.01%)",
    rank: "3-Hold",
    scores: ["Value", "Growth", "Momentum"],
    industryRank: "Top 23% (58 out of 250)",
    industry: "Computer - Software",
    stockActivity: {
      open: "416.75",
      dayLow: "404.51",
      dayHigh: "416.75",
      week52Low: "309.45",
      week52High: "439.42",
    },
  },
  AAPL: {
    name: "Apple",
    symbol: "AAPL",
    price: "$175.23",
    change: "+0.35 (0.20%)",
    afterMarket: "$175.00",
    afterMarketChange: "-0.23 (-0.13%)",
    rank: "1-Strong Buy",
    scores: ["Value", "Momentum", "VGM"],
    industryRank: "Top 15% (37 out of 250)",
    industry: "Technology - Hardware",
    stockActivity: {
      open: "176.00",
      dayLow: "173.50",
      dayHigh: "176.25",
      week52Low: "130.00",
      week52High: "200.00",
    },
  },
  // Add more stocks as needed
};

const StockInfoCard = ({ stock }) => (
  <Box p={2}>
    <Typography variant="h6" color="textPrimary">
      {stock.name} ({stock.symbol})
    </Typography>
    <Typography variant="h4" color="green">
      {stock.price}{" "}
      <Typography variant="body2" component="span" color="green">
        USD
      </Typography>
    </Typography>
    <Typography variant="subtitle2" color="green">
      {stock.change}
    </Typography>
    <Typography variant="caption" color="textSecondary">
      Updated May 31, 2024 04:00 PM ET
    </Typography>
    <Typography variant="body2" color="textSecondary">
      After-Market:{" "}
      <span style={{ color: "#d32f2f" }}>{stock.afterMarket}</span>{" "}
      {stock.afterMarketChange} 7:58 PM ET
    </Typography>

    <Box mt={2} mb={2}>
      <Typography variant="body1" color="primary" gutterBottom>
        All Zacks’ Analyst Reports
      </Typography>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="body2">Zacks Rank:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Chip label={stock.rank} color="warning" size="small" />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Style Scores:</Typography>
          </Grid>
          <Grid item xs={8}>
            {stock.scores.map((score, index) => (
              <Chip
                key={index}
                label={score}
                color={index === 0 ? "primary" : "default"}
                size="small"
                sx={{ ml: index > 0 ? 1 : 0 }}
              />
            ))}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Industry Rank:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{stock.industryRank}</Typography>
            <Typography variant="body2">{stock.industry}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>

    <Divider />

    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Quote Overview
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="body2">Open</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            {stock.stockActivity.open}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Day Low</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            {stock.stockActivity.dayLow}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Day High</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            {stock.stockActivity.dayHigh}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">52 Wk Low</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            {stock.stockActivity.week52Low}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">52 Wk High</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            {stock.stockActivity.week52High}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

const RightSideDrawer = ({ open, onClose, onOpen, handleDrawerClose }) => {
  const [selectedStock, setSelectedStock] = useState(stockData.MSFT);

  const handleStockChange = (symbol) => {
    setSelectedStock(stockData[symbol]);
    !open && onOpen();
    // handleDrawerClose();
  };

  return (
    <Drawer variant="permanent" anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={open ? onClose : onOpen}
          edge="end"
          sx={{ color: "lightgray" }}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
          {/* {open ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />} */}
        </IconButton>
      </DrawerHeader>
      <Stacker
        open={open}
        spacing={open ? 2 : 1}
        direction={open ? "row" : "column"}
      >
        <Button
          variant={selectedStock.symbol === "MSFT" ? "contained" : "outlined"}
          onClick={() => handleStockChange("MSFT")}
        >
          MSFT
        </Button>
        <Button
          variant={selectedStock.symbol === "AAPL" ? "contained" : "outlined"}
          onClick={() => handleStockChange("AAPL")}
        >
          AAPL
        </Button>
      </Stacker>
      {open && <StockInfoCard stock={selectedStock} />}
    </Drawer>
  );
};

export default RightSideDrawer;
