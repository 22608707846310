import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Stack } from "@mui/material";

const drawerWidth = "25vw";

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: "cubic-bezier(0.4, 0, 0.2, 1)",
    duration: 800,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: "cubic-bezier(0.4, 0, 0.2, 1)",
    duration: 800,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    border: "1px solid #e5eae1", // Merged border
    minWidth: "90px !important", // Merged minWidth
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .MuiButton-root": {
      justifyContent: "center", // Center the button content
    },
  }),
}));

export const Stacker = styled(Stack)(({ theme, open }) => ({
  justifyContent: "left",
  alignItems: "left",
  padding: theme.spacing(1),
  // marginTop: open ? theme.spacing(2) : theme.spacing(1),
  // padding: open ? theme.spacing(2) : theme.spacing(1),
}));
