import React from "react";
import TextField from "@mui/material/TextField";

function CustomLoginField(props) {
  return (
    <TextField
      name="username"
      autoComplete="username"
      variant="filled"
      placeholder="Enter your email"
      id="username"
      fullWidth
      sx={{
        "& .MuiFilledInput-root": {
          width: "100%",
          height: "50px",
          borderRadius: "8px", // Rounded corners
          border: "none",
          backgroundColor: "#f0f4f9 !important", // Background color of the input field
          "&:hover": {
            backgroundColor: "#f0f4f9 !important", // Same background color on hover
            border: "none",
          },
          "&.Mui-focused": {
            backgroundColor: "#f0f4f9 !important", // Same background color on focus
            border: "none",
          },
          "&::before, &::after": {
            border: "none",
            display: "none",
            // borderBottom: '2px solid #f0f4f9', // Bottom border color
          },
          "&:hover::before": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on hover
          },
          "&.Mui-focused::after": {
            border: "none",
            // borderBottom: '2px solid #f0f4f9', // Same border color on focus
          },
        },
        "& .MuiFilledInput-input": {
          padding: "10px 24px", // Adjust padding for better aesthetics
          color: "#000",
          backgroundColor: "#f0f4f9 !important",
          filter: "grayscale(0.6)",
          margin: "0px 4px 0px 4px",
        },
        "& .MuiFilledInput-input:-webkit-autofill": {
          padding: "10px 24px", // Adjust padding for better aesthetics
          color: "#000",
          backgroundColor: "#f0f4f9 !important", // Background color of the input field
          filter: "grayscale(0.6)",
          margin: "0px 4px 0px 4px",
        },
        "&input#password:-webkit-autofill": {
          backgroundColor: "#f0f4f9 !important",
          filter: "grayscale(0.6)",
          margin: "0px 4px 0px 4px",
        },
      }}
      {...props}
    />
  );
}

export default CustomLoginField;
