import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import AuthProvider, { AuthContext } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <ProtectedRoute>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

function ProtectedRoute({ children, isLoggedInOnly }) {
  const { isLoggedIn } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const queryParams = location.search;
    if (isLoggedIn) {
      // If logged in and route is restricted to logged-in users, redirect to home page
      navigate("/", { replace: true });
    } else if (!isLoggedIn) {
      // If not logged in and route is restricted to non-logged-in users, redirect to login page
      navigate(`/login${queryParams}`, { replace: true });
    }
  }, [isLoggedIn, navigate, location.search]);

  // Render children if conditions are met
  return children;
}

export default App;
