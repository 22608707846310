import React from "react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";

// Define custom buttons with different styles
const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007f06",
  color: "#fff",
  textTransform: "none",
  width: "100px",
  borderRadius: "24px",
  fontSize: "16px",
  fontWeight: "normal",
  "&:hover": {
    backgroundColor: "#0f611c",
  },
}));

const OutlineButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  color: "#000",
  textTransform: "none",
  width: "100px",
  border: "2px solid #007f06",
  borderRadius: "24px",
  fontSize: "16px",
  fontWeight: "normal",
  "&:hover": {
    backgroundColor: "#f0f4f9",
  },
}));

const LogButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007f06",
  color: "#fff",
  textTransform: "none",
  width: "80%",
  borderRadius: "12px",
  padding: "10px",
  fontWeight: "normal",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: "#0f611c",
  },
}));

const BlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#2196f3",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#1976d2",
  },
}));

const TextButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  letterSpacing: "0px",
  justifyContent: "flex-end",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
}));

const BlackTextButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  letterSpacing: "0px",
  minWidth: "0px",
  color: "#000",
  "&:hover": {
    // backgroundColor: "#f0f4f9",
    textDecoration: "underline",
  },
}));

const CustomButton = ({ color, ...props }) => {
  switch (color) {
    case "green":
      return <GreenButton {...props} />;
    case "log":
      return <LogButton {...props} />;
    case "blue":
      return <BlueButton {...props} />;
    case "text":
      return <TextButton {...props} />;
    case "blacktext":
      return <BlackTextButton {...props} />;
    case "outline":
      return <OutlineButton {...props} />;
    default:
      return <Button {...props} />;
  }
};

export default CustomButton;
