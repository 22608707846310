import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { fetchData } from "../../services/apiConfig";
import CustomSuggestionProgress from "../atoms/Progress/CustomSuggestionProgress";

const QuestionTile = ({ question, setNewMessage, alignLeft }) => (
  <Paper
    elevation={3}
    sx={{
      padding: "15px",
      textAlign: "left",
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      backgroundColor: "#fff9f2",
      border: "1px solid #fff9f2",
      borderRadius: "10px",
      boxShadow: "none",
      cursor: "pointer",
      alignSelf: alignLeft ? "flex-start" : "flex-end", // Align left or right
      "&:hover": {
        backgroundColor: "#f2e8d9",
      },
    }}
    onClick={() => setNewMessage(question)}
  >
    <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "normal" }}>
      {question}
    </Typography>
  </Paper>
);

const SuggestionTile = ({ setNewMessage }) => {
  const [suggestQuestions, setSuggestQuestions] = useState([]);

  useEffect(() => {
    // Fetch the suggested questions on component mount
    try {
      const email = localStorage.getItem("email");
      if (email) {
        fetchData("getSuggest", "POST", { email: email }).then((response) => {
          setSuggestQuestions(response);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box sx={{ marginTop: "50px" }}>
      <Typography variant="h6" sx={{ marginBottom: "16px" }}>
        Here are the top questions you can ask Zack:
      </Typography>

      <Grid container spacing={2}>
        {suggestQuestions.length > 0 ? (
          suggestQuestions.map((item, index) => (
            <Grid
              item
              xs={12} // Full width on small screens
              sm={12} // Full width on small screens
              md={6} // 50% width on medium screens
              lg={6} // 50% width on large screens
              key={index}
            >
              {/* Alternate alignLeft for left and right positioning */}
              <QuestionTile
                question={item.prompt}
                setNewMessage={setNewMessage}
                alignLeft={index % 2 === 0} // Align left for even indexes, right for odd
              />
            </Grid>
          ))
        ) : (
          <CustomSuggestionProgress />
        )}
      </Grid>
    </Box>
  );
};

export default SuggestionTile;
