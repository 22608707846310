import React from "react";
import { LinearProgress, styled } from "@mui/material";
import { Opacity } from "@mui/icons-material";

// Create a styled version of LinearProgress to customize animation speed
const SlowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "15px",
  backgroundColor: "#f5f9fc",
  borderRadius: "4px",
  // Override the default linear progress animation with a slower version
  "& .MuiLinearProgress-bar": {
    transition: "transform 5s ease-out", // Adjust duration to make it slower
    backgroundColor: "#007f06",
    opacity: 0.1,
  },
}));

function CustomLinearProgress(props) {
  return (
    <>
      <SlowLinearProgress style={{ width: "100%" }} />
      <SlowLinearProgress style={{ width: "90%" }} />
      <SlowLinearProgress style={{ width: "80%" }} />
    </>
  );
}

export default CustomLinearProgress;
